import _ from 'lodash';
import { sqUsersApi } from '@/sdk';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE, PUSH_WORKBENCH } from '@/core/flux.service';
import { isViewOnlyWorkbookMode } from '@/utilities/utilities';
import { exposedForTesting } from '@/trendData/trend.actions';

/**
 * Toggles the worksheet panel
 */
export function toggleWorksheetPanel(): void {
  const viewOnly = isViewOnlyWorkbookMode();
  flux.dispatch('WORKSHEET_PANEL_TOGGLE', { viewOnly }, PUSH_WORKBENCH);
}

/**
 * Sets the flag determining if links to an Analysis or Topic should open in a new tab.
 *
 * @param preferNewTab - True if the user prefers links to open in a new tab
 */
export function setPreferNewTab(preferNewTab: boolean): void {
  flux.dispatch('SET_PREFER_NEW_TAB', { preferNewTab }, PUSH_WORKBENCH);
}

/**
 * Sets a flag that indicates whether or not to display the "Edit Profile" dialog.
 *
 * @param display - true if "Edit Profile" should be shown, false otherwise.
 */
export function setUserProfileDisplay(display: boolean): void {
  flux.dispatch('SET_DISPLAY_USER_PROFILE', { displayUserProfile: display }, PUSH_WORKBENCH);
}

/**
 * Passes in a description of the params of the state being transitioned to
 *
 * @param {IStateParamsService} stateParams - the state params from app.module
 */
export function setStateParams(stateParams: any): void {
  flux.dispatch('SET_STATE_PARAMS', { stateParams }, PUSH_IGNORE);
}

/**
 * If a user is logged in, it sets the properties of the currently logged in user. It uses the authentication token
 * to retrieve the user id as this is what allows the app to bootstrap when being loaded initially. After this the
 * user id will be available on sqWorkbenchStore.currentUser.
 *
 * @returns A promise that resolves when the current user's properties have been updated.
 */
export function setCurrentUser(): Promise<any> {
  return sqUsersApi.getMe().then(({ data: user }) =>
    flux.dispatch(
      'SET_CURRENT_USER',
      _.assign(
        {
          isAdmin: user.isAdmin,
          isPasswordSettable: user.isPasswordSettable,
        },
        _.pick(user, ['id', 'firstName', 'lastName', 'name', 'email', 'username', 'workbench']),
      ),
      PUSH_IGNORE,
    ),
  );
}

/**
 * Sets the user's time zone
 *
 * @param timeZone - Time zone to set for the current user
 * @param fetchCapsules - true if changing tz while capsule table is visible
 * @param timeZone.name - Name of the time zone to set
 */
export function setUserTimeZone(timeZone: object): void {
  flux.dispatch('SET_USER_TIME_ZONE', timeZone);
  exposedForTesting.fetchTableAndChartCapsules();
}

/**
 * Sets the check for if the drag and drop popup should be shown
 *
 * @param showDragAndDropPopup - Determines if the popup should be shown
 */
export function setShowDragAndDropPopup(showDragAndDropPopup: boolean): void {
  flux.dispatch('SET_SHOW_DRAG_AND_DROP_POPUP', showDragAndDropPopup, PUSH_WORKBENCH);
}

/**
 * Sets the journal that is being opened
 *
 * @param journalId - Object of the journal being opened
 */
export function setJournalToOpen(journal: object): void {
  flux.dispatch('SET_JOURNAL_TO_OPEN', journal, PUSH_WORKBENCH);
}

/**
 * Sets the user' language preference
 *
 * @param language - the key identifying the selected language
 */
export function setUserLanguage(language: string): void {
  flux.dispatch('SET_USER_LANGUAGE', language);
}

/**
 * Creates a new interactive session ID
 */
export function generateNewSessionId(): void {
  flux.dispatch('SET_NEW_SESSION');
}

/**
 * Sets the PI Vision home URL so the user doesn't have to keep re-entering it each time they do an export.
 *
 * @param piVisionHomeURL - The home URL of a PI Vision server.
 */
export function setPIVisionHomeURL(piVisionHomeURL: string): void {
  flux.dispatch('SET_PI_VISION_HOME_URL', { piVisionHomeURL }, PUSH_WORKBENCH);
}

/**
 * Dispatches an event to add the provided color to the mostRecentColors array.
 *
 *  @param color - a String representing a color in Hex format (e.g. #cccccc)
 */
export function addRecentColor(color: string): void {
  flux.dispatch('ADD_RECENT_COLOR', { color }, PUSH_WORKBENCH);
}

/**
 * Stores the date (as an ISO string) until which the license expiration message has been snoozed.
 *
 * @param snoozeUntil - the date until which the license expiration message has been snoozed
 */
export function setLicenseExpirationSnooze(snoozeUntil: string): void {
  flux.dispatch('SET_LICENSE_EXPIRATION_SNOOZE', { snoozeUntil }, PUSH_WORKBENCH);
}

/**
 * Sets the loadingItemId that determines if the loading indicator spinners should be shown for a workbook or folder.
 */
export function setLoadingItemId(id: string): void {
  flux.dispatch('WORKBOOK_SET_LOADING_ID', { id }, PUSH_WORKBENCH);
}

/**
 * Sets the opening ItemId that determines if the opening indicator spinners should be shown for a workbook or folder.
 */
export function setOpeningAndLoadingItemId(id: string): void {
  flux.dispatch('WORKBOOK_SET_OPENING_LOADING_ID', { id }, PUSH_WORKBENCH);
}

/**
 * Stores a hash of the system message that has been dismissed
 *
 * @param messageHash
 */
export function setSystemMessageHash(message: string): void {
  flux.dispatch('SET_SYSTEM_MESSAGE_HASH', { message }, PUSH_WORKBENCH);
}

export function setTourShown(tourShown: string): void {
  flux.dispatch('SET_TOUR_SHOWN', { tourShown }, PUSH_WORKBENCH);
}

export function setDarkMode(darkMode: boolean): void {
  flux.dispatch('SET_DARK_MODE', { darkMode }, PUSH_WORKBENCH);
}
