// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { LOCALES } from '@/main/app.constants';
import { getSessionId } from '@/core/sessionId.utilities';
import { base64guid } from '@/utilities/utilities';
import { sqTimezones } from '@/utilities/datetime.constants';
import { InitializeMode, PersistenceLevel, Store } from '@/core/flux.service';
import { MAX_RECENT_COLORS } from '@/trendData/trendData.constants';
import { WORKBENCH_TABSETS } from '@/workbench/workbench.constants';

export class WorkbenchStore extends Store {
  /**
   * This data store is persisted at the workbench level, so it does not
   * change when a different workbook or worksheet is selected.
   */
  persistenceLevel: PersistenceLevel = 'WORKBENCH';

  static readonly storeName = 'sqWorkbenchStore';

  initialize(initializeMode: InitializeMode) {
    const saveState = this.state && initializeMode !== 'FORCE';
    this.state = this.immutable({
      interactiveSessionId: saveState ? this.state.get('interactiveSessionId') : getSessionId(true),
      tabsets: {
        dataSources: WORKBENCH_TABSETS.dataSources.indexOf('connections'),
      },
      // Keep piVisionHomeURL because it is not dehydrated
      piVisionHomeURL: saveState ? this.state.get('piVisionHomeURL') : '',
      worksheetPanelCollapsed: false,
      // View only mode has a different default and is controlled separately because the first iterations of
      // view only mode did not show the worksheet panel at all. Defaulting to closed provides a subtle migration for
      // existing users that might not expect to be able to navigate worksheets and reduces clutter in the view mode
      viewOnlyWorksheetPanelCollapsed: true,
      toursShown: [],
      preferNewTab: true,
      stateParams: saveState ? this.state.get('stateParams') : {},
      previousStateParams: {},
      displayUserProfile: false,
      currentUser: saveState ? this.state.get('currentUser') : {}, // Keep currentUser because it is not dehydrated
      userTimeZone: undefined,
      userLanguage: LOCALES.EN, // until we feel confident in our translations we will default to English and not the
      // browser locale for language settings
      recentColors: [],
      loadingItemId: null,
      openingItemId: null,
      systemMessageHash: null,
      licenseExpirationSnoozeUntil: null,
      showDragAndDropPopup: true,
      journalToOpen: null,
      darkMode: false,
    });
  }

  /**
   * @property {String} Unique identifier for this interactive session, which corresponds to a browser tab. It
   *   is intentionally not stored in the browser (e.g. sessionStorage, localStorage, cookies) so that two
   *   different tabs within the same browser have different IDs.
   */
  get interactiveSessionId() {
    return this.state.get('interactiveSessionId');
  }

  /**
   * @property {Object} tabsets An object map of the tabsets managed by the worksheet store
   */
  getTabset(name) {
    return {
      activeTabIndex: this.state.get('tabsets', name),
      tabs: WORKBENCH_TABSETS[name],
    };
  }

  /**
   * Indicates whether the given system message has been dismissed
   *
   * @param message - the system message
   * @return {boolean} whether the given message has been dismissed
   */
  isSystemMessageDismissed(message) {
    return this.state.get('systemMessageHash') === this.hashCodeFromString(message);
  }

  get piVisionHomeURL() {
    return this.state.get('piVisionHomeURL');
  }

  get worksheetPanelCollapsed() {
    return this.state.get('worksheetPanelCollapsed');
  }

  get viewOnlyWorksheetPanelCollapsed() {
    return this.state.get('viewOnlyWorksheetPanelCollapsed');
  }

  get preferNewTab() {
    return this.state.get('preferNewTab');
  }

  get stateParams() {
    return this.state.get('stateParams');
  }

  get previousStateParams() {
    return this.state.get('previousStateParams');
  }

  get currentUser() {
    return this.state.get('currentUser');
  }

  get userTimeZone() {
    return this.state.get('userTimeZone');
  }

  get userLanguage(): string {
    return this.state.get('userLanguage');
  }

  get displayUserProfile() {
    return this.state.get('displayUserProfile');
  }

  get recentColors() {
    return this.state.get('recentColors');
  }

  get loadingItemId() {
    return this.state.get('loadingItemId');
  }

  get openingItemId() {
    return this.state.get('openingItemId');
  }

  get toursShown() {
    return this.state.get('toursShown');
  }

  get licenseExpirationSnoozeUntil() {
    return this.state.get('licenseExpirationSnoozeUntil');
  }

  isLicenseExpirationSnoozed() {
    const snoozeDate = this.state.get('licenseExpirationSnoozeUntil');
    // moment() without arguments returns the current time
    return !!snoozeDate && moment(snoozeDate).isAfter(moment());
  }

  /**
   * If the user has set a time zone, then that time zone will be used. Otherwise, the default timezone is used.
   */
  get timezone() {
    return this.state.get('userTimeZone') || sqTimezones.defaultTimezone;
  }

  get showDragAndDropPopup() {
    return this.state.get('showDragAndDropPopup');
  }

  get journalToOpen() {
    return this.state.get('journalToOpen');
  }

  get darkMode() {
    return this.state.get('darkMode');
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @returns {Object} The dehydrated state.
   */
  dehydrate() {
    const state = _.omit(this.state.serialize(), [
      'currentUser',
      'interactiveSessionId',
      'loadingItemId',
      'openingItemId',
      'displayUserProfile',
      'stateParams',
      'previousStateParams',
      // The properties below are deprecated so we list them here to remove them so they don't persist indefinitely
      'workbooksFilter',
      'workbooksType',
      'workbooksSearch',
      'workbooksSortField',
      'workbooksSortAsc',
      'workbooksView',
      'displaySelector',
      'helpTourCompleted',
      'helpTourShown',
      'chartConfigurationTourDismissed',
    ]) as any;
    state.userTimeZone = _.get(state, 'userTimeZone.name');

    return state;
  }

  /**
   * Re-creates the workbench state.
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   * @return {Object} A promise that is fulfilled when the workbench is completely rehydrated.
   */
  rehydrate(dehydratedState) {
    dehydratedState.userTimeZone = _.find(sqTimezones.timezones, {
      name: dehydratedState.userTimeZone,
    });

    this.state.merge(_.assign({ currentUser: this.state.get('currentUser') }, dehydratedState));
  }

  protected readonly handlers = {
    /**
     * Updates which tab is currently active.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.tabset - Tabset name, one of WORKBENCH_TABSETS keys
     * @param {String} payload.activeTab - Name of tab to make active
     */
    TABSET_CHANGE_TAB: (payload) => {
      if (_.has(WORKBENCH_TABSETS, payload.tabset)) {
        this.state.set(['tabsets', payload.tabset], WORKBENCH_TABSETS[payload.tabset].indexOf(payload.activeTab));
      }
    },
    /**
     * Toggles the display of the worksheet panel on/off.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.viewOnly - true if the worksheet panel is collapsed in view mode
     */
    WORKSHEET_PANEL_TOGGLE: (payload) => {
      if (payload.viewOnly) {
        this.state.set('viewOnlyWorksheetPanelCollapsed', !this.state.get('viewOnlyWorksheetPanelCollapsed'));
      } else {
        this.state.set('worksheetPanelCollapsed', !this.state.get('worksheetPanelCollapsed'));
      }
    },
    SET_TOUR_SHOWN: ({ tourShown }) => {
      this.state.push('toursShown', tourShown);
    },
    /**
     * Sets the flag determining if links to an Analysis or Topic should open in a new tab.
     *
     * @param preferNewTab - True if the user prefers links to open in a new tab
     */
    SET_PREFER_NEW_TAB: ({ preferNewTab }: { preferNewTab: boolean }) => {
      this.state.set('preferNewTab', preferNewTab);
    },
    /**
     * Sets the stateParams to let them be accessible during a state transition
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object} payload.stateParams - the current stateParams
     */
    SET_STATE_PARAMS: (payload) => {
      this.state.set('previousStateParams', this.state.get('stateParams'));
      this.state.set('stateParams', payload.stateParams);
      // Updates to the state params must be immediate to avoid worksteps leaking (see WorkbenchWrapper)
      this.state.commit();
    },
    /**
     * Set the flag that determines if the "Edit Profile" window should be shown
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.displayUserProfile - True if "Edit Profile" should be shown, false otherwise
     */

    SET_DISPLAY_USER_PROFILE: (payload) => {
      this.state.set('displayUserProfile', payload.displayUserProfile);
    },
    /**
     * Sets the properties of the currently logged in user.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.username - The username of this user.
     * @param {String} payload.firstName - The first name of this user.
     * @param {String} payload.lastName - The last name of this user.
     */
    SET_CURRENT_USER: (payload) => {
      this.state.set('currentUser', payload);
    },
    /**
     * Sets the user's time zone.
     *
     * @param {Object} timeZone - Time zone to set for the current user
     * @param {String} timeZone.name - Name of the time zone
     */
    SET_USER_TIME_ZONE: (timeZone) => {
      this.state.set('userTimeZone', timeZone);
    },
    /**
     * Sets the check to determine if the popup should be shown.
     *
     * @param {Boolean} showPopup - If the popup should be shown or not
     */
    SET_SHOW_DRAG_AND_DROP_POPUP: (showDragAndDropPopup) => {
      this.state.set('showDragAndDropPopup', showDragAndDropPopup);
    },
    /**
     * Sets the journal that is being opened.
     *
     * @param {Object} journal - The journal that is being opened
     */
    SET_JOURNAL_TO_OPEN: (journal) => {
      this.state.set('journalToOpen', journal);
    },
    /**
     * Sets the PI Vision home URL.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.piVisionHomeURL - The PI Vision home URL, stored for reuse in further exports to PI
     *   Vision
     */
    SET_PI_VISION_HOME_URL: (payload) => {
      this.state.set('piVisionHomeURL', payload.piVisionHomeURL);
    },
    SET_USER_LANGUAGE: (language) => {
      this.state.set('userLanguage', language);
    },
    /**
     * This function adds the most recently used color to the recentColors array.
     * The size of the array is limited to MAX_RECENT_COLORS.
     * To ensure the most recent color is always shown first the colors is first removed (if found) and then added as
     * the array is reversed on display.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.color - The most recent color represented as a Hex code (e.g. #ff00ff)
     */
    ADD_RECENT_COLOR: (payload) => {
      const color = _.toLower(payload.color);
      const colors = (_.chain(this.state.get('recentColors')).clone().pull(color) as any)
        .push(color)
        .thru(function (recents) {
          return recents.length > MAX_RECENT_COLORS ? _.tail(recents) : recents;
        })
        .value();
      this.state.set('recentColors', colors);
    },
    /**
     * Sets the loadingItemId.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.id - the folder or workbookId
     */
    WORKBOOK_SET_LOADING_ID: (payload) => {
      this.state.set('loadingItemId', payload.id);
    },
    /**
     * Sets the openingItemId as well as the loadingItemId.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Boolean} payload.id - the folder or workbookId
     */
    WORKBOOK_SET_OPENING_LOADING_ID: (payload) => {
      this.state.set('openingItemId', payload.id);
      this.state.set('loadingItemId', payload.id);
    },
    /**
     * Stores a hash of the message that has been dismissed.
     *
     * @param {Object} payload - Object container for arguments
     * @param {number} payload.message - the message that has been dismissed
     */
    SET_SYSTEM_MESSAGE_HASH: (payload) => {
      this.state.set('systemMessageHash', this.hashCodeFromString(payload.message));
    },
    /**
     * Stores the date (as an ISO string) until which the license expiration message has been snoozed.
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.snoozeUntil
     */
    SET_LICENSE_EXPIRATION_SNOOZE: (payload) => {
      this.state.set('licenseExpirationSnoozeUntil', payload.snoozeUntil);
    },
    SET_DARK_MODE: (payload) => {
      this.state.set('darkMode', payload.darkMode);
    },
  };

  /**
   * Returns a new interactive session ID, which is just a GUID in base64
   *
   * @returns {String} interactive session ID
   */
  generateInteractiveSessionId() {
    return base64guid();
  }

  /**
   * Generates and sets a new Session ID
   */
  setNewSession() {
    this.state.set('interactiveSessionId', this.generateInteractiveSessionId());
  }

  hashCodeFromString(stringToEncode: string) {
    let hash = 0;
    if (!stringToEncode) {
      return hash;
    }
    for (let i = 0; i < stringToEncode.length; i++) {
      hash = (hash << 5) - hash + stringToEncode.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
}
